import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  DELETE_NOTIFICATIONS_REQUEST,
  DELETE_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATIONS_ERROR,
  FILTER_SECTOR,
  COMMERCIAL_DBS,
  PARTNERSHIPS_PROJECTS,
  GCC_PROJECTS,
  FEMTECH_ANALYTICS_PROJECTS
} from "./constants";

const initialState = {
  loading: false,
  notificationsList: null,
  dataLength: null,
  sectorName: "All Projects",
  isCommercialDashboard: false
};

export default function notificationsData(state = initialState, action) {
  console.log("action", action);
  console.log("state", state);
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsList: action.data,
        dataLength: action.data.length,
        loading: false
      };
    case GET_NOTIFICATIONS_ERROR:
      return { ...state, loading: false };

    case COMMERCIAL_DBS:
      return { ...state, isCommercialDashboard: action.data, sectorName: "All Projects", loading: false };

    case PARTNERSHIPS_PROJECTS:
      return { ...state, isPartnerships: action.data, sectorName: "All Projects", loading: false };

    case FEMTECH_ANALYTICS_PROJECTS:
      return { ...state, isFemtechAnalyticsProject: action.data, sectorName: "All Projects", loading: false };

    case GCC_PROJECTS:
      return { ...state, isGCCProject: action.data, sectorName: "All Projects", loading: false };

    case DELETE_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case DELETE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsList: action.data,
        dataLength: action.data.length,
        loading: false
      };
    case DELETE_NOTIFICATIONS_ERROR:
      return { ...state, loading: false };
    case FILTER_SECTOR:
      return { ...state, sectorName: action.data, loading: false };
    default:
      return state;
  }
}
