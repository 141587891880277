// NavigationSidebar.jsx
import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button } from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    ProjectOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

const { Sider } = Layout;

// Key for localStorage
const SIDEBAR_COLLAPSED_KEY = 'project_side_bar';

const NavigationSidebar = ({ projects, currentProjectId }) => {
    // Initialize state from localStorage or default to false
    const [collapsed, setCollapsed] = useState(() => {
        const savedState = localStorage.getItem(SIDEBAR_COLLAPSED_KEY);
        return savedState ? JSON.parse(savedState) : false;
    });
    

    // Update localStorage whenever collapsed state changes
    useEffect(() => {
        localStorage.setItem(SIDEBAR_COLLAPSED_KEY, JSON.stringify(collapsed));
    }, [collapsed]);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const getMenuItems = () => {
        return projects?.map(project => ({
            key: project?._id,
            icon: <ProjectOutlined />,
            url: `/project/${project.projectId}?projectId=${project._id}`,
            label: project?.projectName || 'Unnamed Project',
        })) || [];
    };

    return (
        <Sider
            theme="light"
            width={250}
            collapsible
            collapsed={collapsed}
            trigger={null}
            style={{
                overflow: 'auto',
                position: 'relative',
                left: 0,
                top: 0,
                bottom: 0,
                zIndex: 1000,
            }}
        >
            <div style={{
                padding: '16px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                {!collapsed && <h3 style={{ margin: 0 }}>Projects</h3>}
                <Button
                    type="text"
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={toggleCollapsed}
                    style={{
                        fontSize: '16px',
                        width: 64,
                        height: 64,
                    }}
                />
            </div>
            <Menu
                mode="inline"
                selectedKeys={[currentProjectId]}
                style={{
                    borderRight: 0
                }}
            >
                {getMenuItems().map(menuItem => (
                    <Menu.Item key={menuItem.key}>
                        <Link to={menuItem?.url} target='_blank' style={{ margin:0, padding:0 }}>
                            {menuItem.icon}
                            <span>{menuItem.label}</span>
                        </Link>
                    </Menu.Item>
                ))}
            </Menu>
        </Sider>
    );
};

export default NavigationSidebar;