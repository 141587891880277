import { Col, Modal, Row, Spin } from "antd";
import React from "react";
import DealDetailHeader from "./components/DealDetailHeader";
import DealDetailIntro from "./components/DealDetailIntro";
import InvestorDealDetail from "./InvestorDealDetail";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";

import "./style.scss";
import AdminDealDetail from "./AdminDealDetail";
import DocumentsList from "./tabs/dataRoom/DocumentsList";
import useManageDealDetail from "./useManageDealDetail";
import IframeComponent from "./components/IframeComponent";
import ProjectNotFound from "./components/ProjectNotFound";
import LogsDisplay from "./LogsDisplay";
import ProjectCard from "../marketplacePage/ProjectCard";
import NavigationSidebar from "./NavigationSidebar";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const { error } = Modal;



function DealDetails() {
  const allowedRoles = [ "Admin", "Partner", "Organisation" ]
  const [ showLogs, setShowLogs ] = React.useState(false)
  const {
    dealDetails,
    loading,
    userRole,
    user,
    projectData,
    displayDataRoom,
    setDisplayDataRoom,
    showProjectDataFiles,
    setShowProjectDataFiles,
    dataRoomLink,
    projectNotFound,
    projectLogs,
    relatedProjects
  } = useManageDealDetail()

  console.log("dealDetailsdealDetailsdealDetailsdealDetailsdealDetailsdealDetails: ", dealDetails)

  if (projectNotFound) return <ProjectNotFound />;
  return (
    dealDetails && (
      <>
      <div style={{ width: "100%", display: "flex", gap: "10px" }}>
          {
            projectData?.project?.subDataRoomsProjects?.length  ?
            <NavigationSidebar
              projects={projectData?.project?.subDataRoomsProjects}
              currentProjectId={projectData?.project?._id}
            /> :
            null
          }
      <div className="deal-detail" style={{flex: 1}}>
        {loading && (
          <div className="spinner_background">
            <Spin className="spinner" indicator={antIcon} />
          </div>
        )}
        {(allowedRoles.includes(userRole) ) && (
          <>
            <DealDetailHeader
              projectData={projectData?.project}
              countViews={projectData?.countProjectViews}
              userRole={userRole}
              setDisplayDataRoom={setDisplayDataRoom}
              displayDataRoom={displayDataRoom}
              setShowProjectDataFiles={setShowProjectDataFiles}
              showProjectDataFiles={showProjectDataFiles}
              setShowLogs={setShowLogs}
              showProjectLogs={showLogs}
            />
            {showProjectDataFiles === "data_room" ? (
              <>
                {dataRoomLink ? (
                  <>
                  {
                    projectData?.project?._id !== "6736032b9fa6cb1873ea9bcf" && ( // temporarily hardcoded for now
                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          type="primary"
                          onClick={() => window.open(dataRoomLink, "_blank")}
                        >
                          Go To Data Room
                        </Button>
                      </div> 
                      
                    )
                  }
                    <IframeComponent title={"Data Room"} source={dataRoomLink} />
                  </>
                )
                : <h1 style={{textAlign:"center", padding:"1rem"}}>Project has no data room link.</h1>
                }
                
                {/* <DocumentsList
                  documents={
                    projectData?.project?.links?.filter(
                      (item) => item?.type === "dataRoomDocs" && !item?.isHidden
                    ) || []
                  }
                  dataRoomLink={dataRoomLink}
                  imagesPath={projectData?.project?.imagesPath}
                  videoLink={projectData?.project?.videoLink}
                  projectName={projectData?.project?.projectName}
                  dealId={123}
                /> */}
              </>
            ) : showProjectDataFiles === "tier_5_data" ? (
              projectData?.project?.tierFiveDocumentUrl ? 
              <IframeComponent title={"TierFive"} source={projectData?.project?.tierFiveDocumentUrl} />
              : <h1 style={{textAlign:"center", padding:"1rem"}}>Project has no tier 5 document.</h1>
            ) : showProjectDataFiles === "pricing_documents" ? (
              <DocumentsList
                  documents={
                    projectData?.project?.links?.filter(
                      (item) => item?.type === "pricing_documents" && !item?.isHidden
                    ) || []
                  }
                  imagesPath={projectData?.project?.imagesPath}
                  videoLink={projectData?.project?.videoLink}
                  projectName={projectData?.project?.projectName}
                  dealId={123}
                />
            )
            : projectData?.project?.company_website ? (
              <IframeComponent title={"DocumentCreatorIftame"} source={projectData?.project?.company_website} />
            ) : (
              ""
            )}
          </>
        )}

        {
          showLogs && <LogsDisplay logs={projectLogs} setShowLogs={setShowLogs} />
        }


        {user.is_superuser && dealDetails && (
          <>
            <DealDetailHeader
              dealDetail={dealDetails?.deal_info}
              userRole={"Admin"}
              setShowLogs={setShowLogs}
              showProjectLogs={showLogs}
              countViews={projectData?.countProjectViews}
            />
            <DealDetailIntro
              dealDetail={dealDetails?.deal_info}
              userRole={userRole}
            />
            <AdminDealDetail dealDetail={dealDetails} userRole={userRole} />
          </>
        )}
        {(userRole === "INV" || userRole === "FAC") && !user.is_superuser && (
          <>
            <DealDetailHeader dealDetail={dealDetails} userRole={userRole} setShowLogs={setShowLogs}
            countViews={projectData?.countProjectViews}
              showProjectLogs={showLogs} />
            <DealDetailIntro dealDetail={dealDetails} userRole={userRole} />
            <InvestorDealDetail dealDetail={dealDetails} userRole={userRole} />
          </>
        )}
      </div>
      </div>

        <Row style={{ marginTop: "2rem", borderTop: "1px solid #ccc", paddingTop: "2rem" }}>
          <Col span={24}>
            <div className="related-projects-section">
              <h2 style={{ marginBottom: "1rem", fontSize: "2rem", fontWeight: "bold" }}>Discover Related Projects</h2>
              <p className="related-projects-description">
                Explore similar projects related to this one.
              </p>
            </div>
          </Col>
          <Row gutter={[30, 30]}>
          {
            relatedProjects?.length ? 
            relatedProjects?.map((project) => (
              <Col md={8} sm={24} key={project._id} className="d-flex-card mb-1" >
                <ProjectCard record={project} index={project?.projectId} />
              </Col>
            ))
            :
            <h1 style={{textAlign:"center", padding:"1rem"}}>No related projects.</h1>
          }
          </Row>
        </Row>
        
      
      </>

    )
  );
}

export default DealDetails;
