import { InvestmentsIcon } from "../../assets/sideIcons/Investments";
import { DashboardIcon } from "../../assets/sideIcons/Dashboards";
import { OrganizerIcon } from "../../assets/sideIcons/Organizers";
import { ServiceMarketplaceIcon } from "../../assets/sideIcons/ServiceMarketplace";
import { GrandMarketplaceIcon } from "../../assets/sideIcons/GrantMarketplace";
import { UsersIcon } from "../../assets/sideIcons/Users";
import { FacilitatorsIcon } from "../../assets/sideIcons/Facilitators";
import { InvestmentTechMarketplaceIcon } from "../../assets/sideIcons/InvestTechMarketplace";
import { IPMarketplaceIcon } from "../../assets/sideIcons/IPMarketplace";
import { IPsIcon } from "../../assets/sideIcons/IPsIcon";
import { Investor } from "../../assets/sideIcons/Investor";
import { IPInvestment } from "../../assets/sideIcons/IPInvestment";
import { getUser } from "../../Utils/isAuth";

const SidenavHandler = () => {
  const user = getUser();

  let sideNavItems = [
    {
      checkUserRole: true,
      key: "0",
      path: "/",
      icon: InvestmentTechMarketplaceIcon,
      title: "Discover Projects",
      description: "investTech-marketplace"
    }
    // {
    //   checkUserRole: true,
    //   key: "13",
    //   path: "/ip-marketplace",
    //   icon: IPMarketplaceIcon,
    //   title: "IP Marketplace",
    //   description: "ip-marketplace",
    // },
    // {
    //   checkUserRole: isStartup(),
    //   key: "14",
    //   path: "/investor-relations",
    //   icon: Investor,
    //   title: "Investors",
    //   description: "investor-relation",
    // },
    // {
    //   checkUserRole: true,
    //   key: "9",
    //   path: "/MAmarketplace",
    //   icon: MAMArketplaceIcon,
    //   title: "M & A Marketplace",
    //   description: "m&a-marketplace",
    // },
    // {
    //   checkUserRole: true,
    //   key: "6",
    //   path: "/chat",
    //   icon: ChatIcon,
    //   title: "Chat",
    //   description: "chats",
    // },
    // {
    //   checkUserRole: true,
    //   key: "4",
    //   path: "/settings",
    //   icon: SettingsIcon,
    //   title: "Settings",
    //   description: "settings",
    // },
  ];

  const otherArray = [
    {
      checkUserRole: true,
      key: "1",
      path: "/",
      icon: GrandMarketplaceIcon,
      title: "Partnerships",
      description: "partnerships"
    },
    {
      checkUserRole: true,
      key: "2",
      path: "/",
      icon: ServiceMarketplaceIcon,
      title: "Whitelabel Solutions",
      description: "whitelabel-solutions"
    },
    {
      checkUserRole: true,
      key: "3",
      path: "/",
      icon: OrganizerIcon,
      title: "Investable Projects",
      description: "investable-projects"
    },
    {
      checkUserRole: true,
      key: "4",
      path: "/",
      icon: DashboardIcon,
      title: "Business Cooperation",
      description: "business-cooperation"
    },
    {
      checkUserRole: true,
      key: "5",
      path: "/",
      icon: IPInvestment,
      title: "Analytical Dashboards",
      description: "analytical-dashboards"
    },
    {
      checkUserRole: true,
      key: "6",
      path: "/",
      icon: InvestmentsIcon,
      title: "Ecosystems As A Service",
      description: "Ecosystems-as-a-Service"
    },
    {
      checkUserRole: true,
      key: "7",
      path: "/",
      icon: IPsIcon,
      title: "Longevity Projects",
      description: "longevity-projects"
    },
    {
      checkUserRole: true,
      key: "8",
      path: "/",
      icon: UsersIcon,
      title: "DeepTech Projects",
      description: "deepTech-projects"
    },
    {
      checkUserRole: true,
      key: "9",
      path: "/",
      icon: FacilitatorsIcon,
      title: "Philanthropy Projects",
      description: "philanthropy-projects"
    },
    {
      checkUserRole: true,
      key: "10",
      path: "/",
      icon: IPMarketplaceIcon,
      title: "Thematic Projects",
      description: "thematic-projects"
    }
  ].filter(({ title }) => user && (user?.categories?.[0] === "all" || user?.categories?.includes(title)));

  const navBarOptions = {
    "All Projects": [
      {
        checkUserRole: true,
        key: "0",
        path: "/",
        icon: InvestmentTechMarketplaceIcon,
        title: "All Projects",
        description: "investTech-marketplace"
      }
    ],
    "Thematic Projects": [
      {
        checkUserRole: true,
        key: "1",
        path: "/",
        icon: IPsIcon,
        title: "Longevity Projects",
        description: "longevity-projects"
      },
      // {
      //   checkUserRole: true,
      //   key: "2",
      //   path: "/",
      //   icon: UsersIcon,
      //   title: "BioMed Projects",
      //   description: "biomed-projects",
      // },
      {
        checkUserRole: true,
        key: "2",
        path: "/",
        icon: IPMarketplaceIcon,
        title: "SaaS Projects",
        description: "saas-projects"
      },
      {
        checkUserRole: true,
        key: "3",
        path: "/",
        icon: UsersIcon,
        title: "AI Industry Projects",
        description: "ai-industry-projects"
      },
      {
        checkUserRole: true,
        key: "4",
        path: "/",
        icon: UsersIcon,
        title: "Analytical Dashboards",
        description: "analytical-projects"
      },
      {
        checkUserRole: true,
        key: "5",
        path: "/",
        icon: UsersIcon,
        title: "GovTech Projects",
        description: "govTech-projects"
      },
      {
        checkUserRole: true,
        key: "6",
        path: "/",
        icon: UsersIcon,
        title: "DeepTech Projects",
        description: "deepTech-projects"
      },
      {
        checkUserRole: true,
        key: "7",
        path: "/",
        icon: FacilitatorsIcon,
        title: "Philanthropy Projects",
        description: "philanthropy-projects"
      },
      {
        checkUserRole: true,
        key: "8",
        path: "/",
        icon: IPMarketplaceIcon,
        title: "Life Sciences Projects",
        description: "life-sciences-projects"
      },
      {
        checkUserRole: true,
        key: "14",
        path: "/femtech",
        icon: "",
        title: "Femtech Analytics Projects",
        description: "femtech-analytics-projects"
      }
    ],
    // "White Label Solutions": [
    //   {
    //     checkUserRole: true,
    //     key: "9",
    //     path: "/",
    //     icon: ServiceMarketplaceIcon,
    //     title: "Whitelabel Solutions",
    //     description: "whitelabel-solutions",
    //   }
    // ],
    // "Ecosystem Mapping": [
    //   {
    //     checkUserRole: true,
    //     key: "10",
    //     path: "/",
    //     icon: InvestmentsIcon,
    //     title: "Ecosystems As A Service",
    //     description: "Ecosystems-as-a-Service",
    //   }
    // ],
    // "Dashboards":[
    //   {
    //     checkUserRole: true,
    //     key: "11",
    //     path: "/",
    //     icon: OrganizerIcon,
    //     title: "Investable Projects",
    //     description: "investable-projects",
    //   }
    // ],
    "Joint Projects": [
      {
        checkUserRole: true,
        key: "10",
        path: "/",
        icon: DashboardIcon,
        title: "Business Cooperation",
        description: "business-cooperation"
      },
      {
        checkUserRole: true,
        key: "11",
        path: "/",
        icon: ServiceMarketplaceIcon,
        title: "Whitelabel Solutions",
        description: "whitelabel-solutions"
      },
      {
        checkUserRole: true,
        key: "12",
        path: "/",
        icon: OrganizerIcon,
        title: "Investable Projects",
        description: "investable-projects"
      },
      {
        checkUserRole: true,
        key: "13",
        path: "/",
        icon: InvestmentsIcon,
        title: "Ecosystems As A Service",
        description: "Ecosystems-as-a-Service"
      }
    ],
    "Commercial Projects": [
      {
        checkUserRole: true,
        key: "131",
        path: "/dashboards",
        icon: InvestmentsIcon,
        title: "Dashboards",
        description: "Comercial Dashboards"
      },
      {}
    ],
    Partnerships: [
      {
        checkUserRole: true,
        key: "9",
        path: "/partnerships",
        icon: GrandMarketplaceIcon,
        title: "Partnerships",
        description: "partnerships"
      },
      {
        checkUserRole: true,
        key: "224",
        path: "/gcc",
        icon: GrandMarketplaceIcon,
        title: "Partnerships in GCC Region",
        description: "Partnerships in GCC Region"
      }
    ]
  };

  sideNavItems = sideNavItems.concat(otherArray);

  return {
    sideNavItems,
    navBarOptions
  };
};

export default SidenavHandler;
